<template>
  <div class="footer">
    <div class="footer__container">
      <div class="footer__container-logo">
        <img
          class="cursor-pointer"
          @click="pushRoute('')"
          src="@/assets/images/logo.png"
          alt=""
        />
        <p v-if="!mobile">© ATTIK STUDIO, 2024</p>
      </div>
      <div v-if="mobile" class="footer__container-social">
        <a href="tel:+7 999 720 20 50"><p>+7 999 720 20 50</p></a>
        <a href="mailto:attik.interiors@gmail.com"
          ><p>attik.interiors@gmail.com</p></a
        >
        <div class="flex items-center gap-4 mt-1">
          <a href="https://t.me/Attik_studio">
            <img
              height="25"
              width="25"
              src="@/assets/images/telegram.svg"
              alt=""
            />
          </a>
          <a href="https://www.instagram.com/attik_design">
            <img
              height="25"
              width="25"
              src="@/assets/images/instagram.svg"
              alt=""
            />
          </a>
        </div>
      </div>
      <div class="footer__container-links">
        <div class="footer__container-links-left">
          <span @click="pushRoute('projects')">Проекты</span>
          <span @click="pushRoute('', 'services')">Услуги</span>
          <span @click="pushRoute('', 'steps')">Этапы работы</span>
        </div>
        <div class="footer__container-links-right">
          <span @click="pushRoute('', 'contacts')">Контакты</span>
          <span @click="pushRoute('articles')">Статьи</span>
        </div>
      </div>
      <div class="footer__container-social">
        <a v-if="!mobile" href="tel:+7 999 720 20 50"
          ><p>+7 999 720 20 50</p></a
        >
        <a v-if="!mobile" href="mailto:attik.interiors@gmail.com"
          ><p>attik.interiors@gmail.com</p></a
        >
        <span v-if="!mobile" @click="pushRoute('privacy')"
          >Политика конфиденциальности</span
        >
        <div v-if="!mobile" class="flex items-center gap-4 mt-1">
          <a href="https://t.me/Attik_studio">
            <img
              height="28"
              width="28"
              src="@/assets/images/telegram.svg"
              alt=""
            />
          </a>
          <a href="https://www.instagram.com/attik_design">
            <img
              height="28"
              width="28"
              src="@/assets/images/instagram.svg"
              alt=""
            />
          </a>
        </div>
        <!-- For Mobile -->
        <div v-if="mobile">
          <p>© ATTIK STUDIO, 2024</p>
          <span @click="pushRoute('privacy')">Политика конфиденциальности</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    pushRoute(slug, block) {
      if (slug) {
        localStorage.removeItem("element");
        this.$router.push("/" + slug);
      } else if (block) {
        const home = this.$route.path;
        if (home === "/") {
          document.getElementById(block).scrollIntoView({ behavior: "smooth" });
        } else {
          localStorage.setItem("element", JSON.stringify(block));
          this.$router.push("/");
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>