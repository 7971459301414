<template>
  <div class="navbar">
    <MobileNavbar v-if="navbarStatus" />
    <div class="navbar__container">
      <img
        class="navbar__container-logo"
        src="@/assets/images/logo.png"
        @click="pushHome"
        alt=""
      />
      <div class="navbar__container-links">
        <span
          v-for="link in links"
          :key="link"
          @click="pushRoute(link.slug, link.block)"
          >{{ link.title }}</span
        >
      </div>
      <div class="navbar__container-phone">
        <a href="tel:+7 999 720 20 50"><span>+7 999 720 20 50</span></a>
      </div>
      <div
        v-if="mobile"
        @click="changeNavbarStatus"
        class="navbar__container-menu"
      >
        <img src="@/assets/images/burger-menu.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import MobileNavbar from "./MobileNavbar.vue";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    MobileNavbar,
  },
  data() {
    return {
      links: [
        {
          title: "Проекты",
          slug: "projects",
        },
        {
          title: "Услуги",
          block: "services",
        },
        {
          title: "Этапы работы",
          block: "steps",
        },
        {
          title: "Контакты",
          block: "contacts",
        },
        {
          title: "Статьи",
          slug: "articles",
        },
      ],
    };
  },
  computed: {
    ...mapState(["navbarStatus"]),
  },
  methods: {
    ...mapMutations(["changeNavbarStatus"]),
    pushHome() {
      this.$router.push("/");
    },
    pushRoute(slug, block) {
      if (slug) {
        localStorage.removeItem("element");
        this.$router.push("/" + slug);
      } else if (block) {
        const home = this.$route.path;
        if (home === "/") {
          document.getElementById(block).scrollIntoView({ behavior: "smooth" });
        } else {
          localStorage.setItem("element", JSON.stringify(block));
          this.$router.push("/");
        }
      }
    },
  },
};
</script>

<style>
</style>