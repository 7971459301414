import { createStore } from 'vuex'

export default createStore({
    state: {
        modalStatus: false,
        successStatus: false,
        navbarStatus: false
    },
    getters: {
        getModalStatus(state) {
            return state.modalStatus
        },
        getSuccessStatus(state) {
            return state.successStatus
        },
        getNavbarStatus(state) {
            return state.navbarStatus
        }
    },
    mutations: {
        changeModalStatus(state, value) {
            state.modalStatus = !state.modalStatus
            document.body.classList.toggle("body-overflow", state.modalStatus);
        },
        changeSuccessStatus(state, value) {
            state.successStatus = !state.successStatus
            document.body.classList.toggle("body-overflow", state.successStatus);
        },
        changeNavbarStatus(state, value) {
            state.navbarStatus = !state.navbarStatus
        }
    },
    actions: {},
    modules: {}
})