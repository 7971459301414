import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
        path: '/',
        name: 'home',
        component: () =>
            import ('../views/Home.vue')
    },
    {
        path: '/projects',
        name: 'projects',
        component: () =>
            import ('../views/Projects/index.vue')
    },
    {
        path: '/articles',
        name: 'articles',
        component: () =>
            import ('../views/Articles/index.vue')
    },
    {
        path: '/articles/:id',
        name: 'articles-id',
        component: () =>
            import ('../views/Articles/ArticleId.vue')
    },
    {
        path: '/projects/:id',
        name: 'projects-id',
        component: () =>
            import ('../views/Projects/ProjectId.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () =>
            import ('../views/Privacy/index.vue')
    }
]

const router = createRouter({
        history: createWebHistory(),
        routes,
        // scrollBehavior(to, from, savedPosition) {
        //     return { top: 0 }
        // },
    })
    // Assuming you're inside a Vue component or Vue Router configuration

router.beforeEach((to, from, next) => {
    window.scrollTo({ top: 0 });
    next();
});
// Register the afterEach navigation guard
// router.afterEach((to, from) => {
//     const element = JSON.parse(localStorage.getItem('element'));
//     if (element) {
//         const targetElement = document.getElementById(element);
//         if (targetElement) {
//             targetElement.scrollIntoView({ behavior: "smooth" });
//         } else {
//             console.error("Element with ID", element, "not found.");
//         }
//     }
// });

export default router