<template>
  <div class="mobile__navbar">
    <div class="mobile__navbar-head">
      <img
        @click="pushHome"
        class="mobile__navbar-head-logo"
        src="@/assets/images/logo.png"
        alt=""
      />
      <img @click="changeNavbarStatus" src="@/assets/images/close.svg" alt="" />
    </div>
    <div class="mobile__navbar-body">
      <span
        v-for="link in links"
        :key="link"
        @click="pushRoute(link.slug, link.block)"
        >{{ link.title }}</span
      >
    </div>
    <div class="mobile__navbar-footer">
      <span>+7 999 720 20 50</span>
      <span>@attik_des</span>
      <span>attik.interiors@gmail.com</span>
      <p>© ATTIK STUDIO, 2024</p>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      links: [
        {
          title: "Проекты",
          slug: "projects",
        },
        {
          title: "Услуги",
          block: "services",
        },
        {
          title: "Этапы работы",
          block: "steps",
        },
        {
          title: "Контакты",
          block: "contacts",
        },
        {
          title: "Статьи",
          slug: "articles",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["changeNavbarStatus"]),
    pushHome() {
      this.$router.push("/");
      this.changeNavbarStatus();
    },
    pushRoute(slug, block) {
      if (slug) {
        localStorage.removeItem("element");
        this.changeNavbarStatus();
        this.$router.push("/" + slug);
      } else if (block) {
        const home = this.$route.path;
        if (home === "/") {
          localStorage.removeItem("element");
          this.changeNavbarStatus();
          document.getElementById(block).scrollIntoView({ behavior: "smooth" });
        } else {
          localStorage.setItem("element", JSON.stringify(block));
          this.changeNavbarStatus();

          this.$router.push("/");
        }
      }
    },
  },
};
</script>

<style lang="scss">
.mobile__navbar {
  background: black;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;
  &-head {
    display: flex;
    justify-content: space-between;
    &-logo {
      height: 2rem;
    }
    img {
      cursor: pointer;
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    span {
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    span {
      font-size: 0.9rem;
      color: rgba(255, 255, 255, 0.8);
    }
    p {
      margin-top: 0.8rem;
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>