<template>
    <router-view/>
    <Footer />
</template>
<script>
import Footer from '@/components/Layout/Footer.vue'
export default {
  components: {
    Footer
  }
}
</script>
<style lang="scss">
</style>
