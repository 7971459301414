<template>
    <button class="white-button" @click="handleScroll">{{ text }}</button>
</template>

<script>
export default {
  props: ["text"],
  methods: {
    handleScroll(){
        const element = document.getElementById('contacts')
        element.scrollIntoView({ behavior: 'smooth' })
    }
  }
};
</script>

<style>
</style>