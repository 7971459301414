import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/footer.scss'
import '@/assets/styles/navbar.scss'
import '@/assets/styles/main.scss'
import '@/assets/styles/variables.scss'
import '@/assets/styles/responsive.scss'
import mobileMixin from './utils/mobileMixin';
import Notifications from '@kyvg/vue3-notification'
import Navbar from '@/components/Layout/Navbar.vue'
import WhiteButton from '@/components/Buttons/WhiteButton.vue'
import BlackButton from '@/components/Buttons/BlackButton.vue'

createApp(App)
    .mixin(mobileMixin)
    .component('Navbar', Navbar).component('WhiteButton', WhiteButton).component('BlackButton', BlackButton).use(Notifications).use(store).use(router).mount('#app')